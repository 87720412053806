var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-steps",
    {
      attrs: {
        active: _vm.activeSteps,
        "finish-status": "success",
        "align-center": "",
      },
    },
    [
      _c("el-step", { attrs: { title: "选择类目" } }),
      _c("el-step", { attrs: { title: "基本信息" } }),
      _c("el-step", { attrs: { title: "完成" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
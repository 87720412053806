<template>
  <el-steps
    :active="activeSteps"
    finish-status="success"
    align-center
  >
    <el-step title="选择类目"></el-step>
    <el-step title="基本信息"></el-step>
    <el-step title="完成"></el-step>
  </el-steps>
</template>

<script>
export default {
  props: {
    activeSteps: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
.is-success {
    color: #20A0FF!important;
    border-color: #20A0FF!important;
}
.is-process {
    color: #20A0FF!important;
    border-color: #20A0FF!important;
}
</style>
